.category-list-item-action {
  width: 512px;
  height: 76px;
  background-color: rgb(255 255 255 / 60%);
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -2px rgb(0 0 0 / 10%);
  border: none;
  border-radius: 16px;
  transition: all 0.2s ease-out;
  cursor: pointer;

  &:hover {
    background-color: rgb(255 255 255 / 100%);
  }

  .category-row--move {
    display: flex;
  }

  .category-row {
    display: flex;
    align-items: center;
    color: white;
    padding: 10px;
    padding-top: 25px;

    .category-row-status {
      border: none;
      border-radius: 16px;
      color: white;
      padding: 4px 12px;
      margin-right: 20px;
      font-size: 12px;
      width: 85px;
      transition: margin-left 0.5s ease;
    }

    .category-row-name {
      color: black;
      font-weight: 600;
      margin-left: 25px;
      width: 265px;
    }


    .category-row-block {
      margin-left: 55px;
      display: flex;
      transition: all 0.2s ease-out;
    }

    &:hover {
      .category-row-block {
        display: flex;
        margin-left: 65px;
      }
    }

    .category-row-status--completed {
      background-color: #22C55E;
      margin-left: 5px;
    }
  }
}


.modal-background-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  z-index: 1000;
  /* Ensure the overlay is above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Styles for the modal */
.modalEditUserProfile {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
  text-align: center;
  z-index: 1001;
  /* Ensure the modal is above the overlay */
}

.modal-content-1UserProfile p {
  font-size: 16px;
}

.modal-buttonsUserProfile {
  margin-top: 20px;
}

.modal-ok-buttonUserProfile,
.modal-cancel-buttonUserProfile {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.para_data {
  color: black;
  font-family: "Your Desired Font", sans-serif;
  font-weight: bold;
}

.modal-ok-buttonUserProfile:hover {
  background-color: #0056b3;
}

.modal-cancel-buttonUserProfile:hover {
  background-color: #ff4444;
}