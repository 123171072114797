@import url('https://fonts.googleapis.com/css?family=Exo:400,700');

.hdg {
  text-align: center;
  margin: 2.5rem;
}

.welcomeText {
  text-align: center;
  line-height: 28px;
  color: #d6def3;
  font-weight: 400;
  font-size: 17px;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .welcomeText {
    font-size: 14px; /* Adjust the font size for smaller screens */
    margin-bottom: 1rem; /* Reduce the bottom margin */
    margin-top: 0.5rem; /* Reduce the top margin */
    line-height: 24px; /* Adjust line height for smaller screens */
  }
}

.center-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 50vh; */
}

/* Default styles */
.question-container-actions-next.btn-primary,
.question-container-actions-previous.btn-primary {
  background-color: #fff;
  border-color: rgb(255 255 255 / 20%);
  color: black;
  cursor: pointer;
  opacity: 1;
  display: flex;
  width: 120px;
  padding: 8px 12px;
  height: 36px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  transition: all 0.3s ease; /* Add a smooth transition effect */

  .arrow-left-on-square-icon {
    margin-top: 1px;
  }

  .question-container-actions-button-text {
    margin-right: 5px;
  }

  &:hover,
  &:active {
    background-color: #fff;
    border-color: rgb(255 255 255 / 20%);
    color: black;
    box-shadow:
      0 4px 6px -4px rgb(0 0 0 / 10%),
      0 10px 15px -3px rgb(0 0 0 / 10%);
  }

  &:disabled {
    width: 120px;
    height: 36px;
    background-color: #fff;
    border-color: #fff;
    color: black;
    opacity: 0.8;
    cursor: default;
  }
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .question-container-actions-next.btn-primary,
  .question-container-actions-previous.btn-primary {
    width: auto; /* Let the width be determined by content */
    padding: 8px 12px; /* Adjust padding */
    font-size: 12px; /* Decrease font size for smaller screens */
    gap: 8px; /* Reduce the gap between icon and text */
  }
}

.landing-page-logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 2rem; */
}

/* Default styles */
.landing-page-icon-logo {
  margin: 0;
  width: 6%;
}

.landing-page-name-logo {
  margin: 1rem;
  width: 20%;
}

/* landin page */
.context {
  width: 100%;
  position: absolute;
  top: 4rem;
}

.area {
  background: #006dd2;
  background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
  width: 100%;
  height: 100vh;
  position: fixed;
  /* min-width: 1800px; */
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .landing-page-icon-logo {
    width: 10%; /* Adjust the width for smaller screens */
  }

  .landing-page-name-logo {
    margin: 0.5rem 0; /* Reduce the margin for smaller screens */
    width: 40%; /* Adjust the width for smaller screens */
  }

  .context {
    top: 2rem; /* Adjust the top position for smaller screens */
  }

  .area {
    height: 100vh; /* Keep the same height for smaller screens */
  }
}

/* Default styles */
.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgb(210, 209, 223);
  animation: animate 29s linear infinite;
  bottom: -150px;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .circles li {
    width: 10px; /* Reduce the circle size for smaller screens */
    height: 10px; /* Reduce the circle size for smaller screens */
    bottom: -75px; /* Adjust the bottom position for smaller screens */
  }
}

/* Default styles */
.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 3s;
  animation-duration: 50s;
}

.circles li:nth-child(11) {
  left: 75%;
  width: 60px;
  height: 60px;
  animation-delay: 6s;
  animation-duration: 30s;
}

.circles li:nth-child(12) {
  left: 35%;
  width: 75px;
  height: 75px;
  animation-delay: 7s;
  animation-duration: 40s;
}

.circles li:nth-child(13) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 14s;
  animation-duration: 45s;
}

.circles li:nth-child(14) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(15) {
  left: 85%;
  width: 90px;
  height: 90px;
  animation-delay: 0s;
  animation-duration: 11s;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .circles li {
    width: 15px;
    height: 15px;
  }
}

/* Keyframes animation */
/* @keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
} */

/* Default styles for the button */
.animated-button:hover {
  padding-right: 40px;

  background-color: white;

  color: black;
}

.animated-button:hover .arrow-icon {
  right: 10px;

  opacity: 1;
}

.loadingCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20%;
  padding-bottom: 20%;
}

.animated-button {
  background-color: #dbdaee;
  margin-bottom: 2rem;
  color: black;

  border: none;

  border-radius: 5px;

  padding: 10px 20px;

  cursor: pointer;

  font-size: 18px;

  position: relative;

  overflow: hidden;

  transition: all 0.3s ease;

  margin-top: 2rem;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .animated-button {
    font-size: 16px; /* Decrease font size for smaller screens */
    padding: 8px 16px; /* Adjust padding for smaller screens */
    margin-top: 1rem; /* Adjust top margin for smaller screens */
  }
}

/* Default styles for .button-text */
.button-text {
  z-index: 2;
  position: relative;
}

/* Default styles for .arrow-icon */
.arrow-icon {
  position: absolute;
  top: 50%;
  right: -30px;
  /* margin-left: 20px; */
  transform: translateY(-50%);
  opacity: 0;
  transition: all 0.3s ease;
}

/* Default styles for .animated-button */
.animated-button {
  background-color: #dbdaee;
  color: black;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 18px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  margin-top: 2rem;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .arrow-icon {
    right: -20px;
  }

  .animated-button {
    font-size: 16px;
    padding: 8px 16px;
    margin-top: 1rem;
  }
}

/* Default styles for .animated-button:hover .arrow-icon */
.animated-button:hover .arrow-icon {
  right: 10px;
  opacity: 1;
}

/* Default styles for @keyframes textAnimation */
@keyframes textAnimation {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  /* Adjust the right position and opacity of .animated-button:hover .arrow-icon for smaller screens */
  .animated-button:hover .arrow-icon {
    right: 5px; /* Adjust the right position for smaller screens */
    opacity: 0.8; /* Adjust the opacity for smaller screens */
  }

  /* Adjust the @keyframes textAnimation for smaller screens */
  @keyframes textAnimation {
    0% {
      transform: translateX(0);
    }

    50% {
      transform: translateX(5px);
    }

    100% {
      transform: translateX(0);
    }
  }
}

/* Default styles for .button-text:hover */
.button-text:hover {
  animation: textAnimation 0.5s ease infinite;
}

/* Default styles for .grid-container */
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  padding: 20px;
  margin: 0 auto;
  max-width: calc(100% - 30rem);
  /* background-color: rgba(0, 109, 210, 0.5); */
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  /* Adjust the animation duration for .button-text:hover for smaller screens */
  .button-text:hover {
    animation: textAnimation 0.3s ease infinite; /* Decrease the duration for smaller screens */
  }

  /* Adjust the grid layout for smaller screens */
  .grid-container {
    grid-template-columns: repeat(2, 1fr); /* Adjust the number of columns for smaller screens */
    gap: 20px; /* Reduce the gap between grid items for smaller screens */
    padding: 10px; /* Adjust the padding for smaller screens */
    max-width: 100%; /* Remove the maximum width constraint for smaller screens */
  }
}

/* Default styles for .grid-item */
.grid-item {
  background-color: transparent;
  padding: 20px;
  height: auto;
  text-align: center;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  padding-top: 50px;
}

/* Default styles for .grid-item:hover */
/* .grid-item:hover {
  transform: scale(1.05);
} */

/* Default styles for .grid-item h1 */
.grid-item h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

/* Default styles for .grid-item p */
.grid-item p {
  font-size: 16px;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  /* Adjust padding and font size for .grid-item for smaller screens */
  .grid-item {
    padding: 10px; /* Adjust the padding for smaller screens */
  }

  .grid-item h1 {
    font-size: 20px;
  }

  .grid-item p {
    font-size: 14px;
  }
}
