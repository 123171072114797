.App01 .content01 {
  min-height: calc(100vh - 150px);
  max-width: 800px;
  margin: 2rem auto 0 auto;
  padding: 0 1rem;
}

.App01 .content01 .app-description {
  text-align: center;
}

.App01 .content01 .app-description p {
  line-height: 1.75rem;
}

.accordion-wrapper .accordion-item h3.accordion-title {
  font-size: 1.75rem;
  margin: 0;
}

.accordion-wrapper .accordion-item h3.accordion-title button {
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgb(255 255 255 / 60%);
  box-shadow:
    0 4px 6px -1px rgb(0 0 0 / 10%),
    0 2px 4px -2px rgb(0 0 0 / 10%);
  border-radius: 1rem;
  border: none;
  font-size: 1.2rem;
  width: 100%;
  text-align: left;
  color: black;
  font-weight: 600;
  margin-top: 0.5rem;
  padding: 4%;
}

.accordion-wrapper .accordion-item h3.accordion-title button.active {
  background-color: rgb(255 255 255 / 60%);
  box-shadow:
    0 4px 6px -1px rgb(0 0 0 / 10%),
    0 2px 4px -2px rgb(0 0 0 / 10%);
  color: #000000;
  border:
    0 4px 6px -1px rgb(0 0 0 / 10%),
    0 2px 4px -2px rgb(0 0 0 / 10%);
}

.accordion-wrapper .accordion-item h3.accordion-title button:hover {
  background-color: #ffffff;
}

.accordion-wrapper .accordion-item h3.accordion-title button span.title-wrapper {
  display: block;
  position: relative;
  width: 100%;
}

.accordion-wrapper .accordion-item h3.accordion-title button span.icon-wrapper {
  width: 10%;
  display: flex;
  justify-content: center;
}

.accordion-wrapper .accordion-item h3.accordion-title button span.icon-wrapper span.minus {
  content: url('../../assets/line.svg');
  width: 24px;
  height: 24px;
}

.accordion-wrapper .accordion-item h3.accordion-title button span.icon-wrapper span.plus {
  content: url('../../assets/plus.svg');
  width: 24px;
  height: 24px;
  transform: rotate(90deg);
  transition-timing-function: ease-in;
  transition: all 1s;
}

.accordion-wrapper .accordion-item .accordion-panel .panel-close {
  background-color: rgb(255 255 255 / 60%);
  box-sizing: border-box;
  padding: 0.5rem 1rem 0 1rem;
  opacity: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  transition-timing-function: ease-out;
  transition: all 1s;
}

.no_response {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
.accordion-wrapper .accordion-item .accordion-panel .panel-open {
  overflow: hidden;
  background-color: rgb(197 197 197 / 60%);
  padding: 1rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  border-radius: 1rem;
  opacity: 1;
  width: 100%;
  height: auto;
  transition-timing-function: ease-in;
  transition: all 1s;
}

.accordion-wrapper .accordion-item .accordion-panel p {
  margin: 0;
}

.response-container {
  margin-bottom: 10%;
}
