.category-result {
  margin-top: 50px;

  .nav-tabs .nav-item {
    --bs-nav-tabs-border-width: none;

    border: none;
  }

  .category-result-content-action {
    color: rgb(255 255 255 / 80%);
    display: flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .category-result-content-action-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-link:active,
  .nav-tabs .nav-link:focus-visible {
    background-color: rgb(0 0 0 / 0%);
    color: white;
    border: none;
    border-bottom: 2px solid #fff; /* Add a 2px solid black bottom border */
  }

  .category-result-tab-name {
    color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .category-result-content-action-button,
  .category-result-content-action-button:hover {
    background: none;
    border: none;
    color: rgb(255 255 255 / 80%);
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    padding: 0;
  }

  .category-result-content-action-button-solution {
    background: #0161ba;
  }

  .category-result-content {
    display: flex;
    padding: 64px 0;
    align-items: flex-start;
    gap: 64px;
  }

  .category-result-content-action-button {
    cursor: pointer;

    .question-container-actions-logo {
      border-radius: 6px;

      /* background: #006DD2; */
      mix-blend-mode: screen;
      margin-right: 10px;
      margin-top: 3px;
      width: 20px;
    }
  }

  .category-result-content-action {
    cursor: pointer;
    border-radius: 5px;
    width: 200px;
    display: flex;
    padding: 5px;
    height: 35px;
    opacity: 0.75;
    transition: all 0.2s ease-out;

    &:hover {
      height: 35px;
      background-color: rgb(255 255 255 / 10%);

      .category-result-content-action-button {
        color: rgb(255 255 255 / 100%);
      }
    }
  }

  .category-result-solution {
    color: white;
    width: 70%;
  }

  .category-result-no-results-html {
    text-align: justify;
    width: 100%;
  }

  .category-result-no-results-html li {
    font-size: 14px;
  }

  .category-result-no-results-html p {
    font-size: 14px;
  }

  .category-result-no-results-html h3 {
    padding-top: 10px;
    font-size: 26px;
  }
  .category-result-no-results-html h2 {
    padding-top: 10px;
    font-size: 26px;
  }

  .category-result-no-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    gap: 16px;
  }

  .category-result-content-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 184px;
    gap: 8px;
  }
  .clear_text{
    padding-left: 30px;
  }

  .category-result-content-action-solution {
    background: #0060b8;
    border: 1px solid rgb(255 255 255);

    .question-container-actions-logo {
      border-radius: 6px;
      background: #0060b8;
      mix-blend-mode: screen;
      margin-right: 10px;
      margin-top: -3px;
      width: 20px;
    }

    &:hover {
      background: #0060b8;
    }
  }
}


.category-result-content-action-solution-chain {
  width: 70% !important;
}
