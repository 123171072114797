.App {
  text-align: center;
  background-color: #006dd2;
}

body {
  background-color: #006dd2;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.loading-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.question-container-actions-generate1 {
  background: #006dd2;
  border: none;
  /* background-color: #006dd2; */
  margin-top: 15%;
}

.question-container-actions-logo1 {
  /* border-radius: 6px; */

  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  mix-blend-mode: screen;
  width: 75px;
}

/* Add this CSS code to your stylesheets, e.g., App.css */
@keyframes loadingTextAnimation {
  0% {
    opacity: 0.3;
    transform: translateY(0);
  }

  50% {
    opacity: 1;
    transform: translateY(-10px);
  }

  100% {
    opacity: 0.3;
    transform: translateY(0);
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}