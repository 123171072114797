.question-container {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;

  max-width: 1140px;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;

  .question-container-header-top {
    flex: 0 0 auto;
    width: 50%;
  }

  .question-container-header-top {
    flex: 0 0 auto;
    width: 70%;
  }


.modal-background-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000; /* Ensure the overlay is above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Styles for the modal */
.modalEditUserProfile {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
  text-align: center;
  z-index: 1001; /* Ensure the modal is above the overlay */
}

.modal-content-1UserProfile p {
  font-size: 16px;
}

.modal-buttonsUserProfile {
  margin-top: 20px;
}

.modal-ok-buttonUserProfile,
.modal-cancel-buttonUserProfile {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.para_data{
  color: black;
  font-family: "Your Desired Font", sans-serif; 
  font-weight: bold;
}

.modal-ok-buttonUserProfile:hover {
  background-color: #0056b3;
}

.modal-cancel-buttonUserProfile:hover {
  background-color: #ff4444;
}

  .active-button {
    border: none;
    padding: 2px 25px 5px 25px;
    font-size: 12px;
    cursor: pointer;
    line-height: 16px;
    font-weight: 600;
    border-bottom: 1px solid white;
    /* background: white; */
    color: white;
  }

  .answered-button {
    background: none;
    color: white;
    font-weight: 400;
    border-bottom: 2px solid white;
    padding: 2px 25px 5px 25px;
    font-size: 12px;
    cursor: pointer;
    line-height: 16px;
    font-weight: 600;
  }

  .unanswered-button {
    cursor: pointer;
    background: none;
    color: #b9b7b7;
    font-weight: 400;
    border-bottom: 1px solid #b9b7b7;
    padding: 2px 25px 5px 25px;
    font-size: 12px;
    font-weight: 400;
  }

  .question-pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5rem;
  }

  .question-pagination .inActive-button {
    border: none;
    cursor: pointer;
    background: none;
    line-height: 16px;
    color: #b9b7b7;
    padding: 2px 25px 5px 25px;
    font-size: 12px;
    font-weight: 400;
    border-bottom: 1px solid #b9b7b7;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
  }

  .question-container-header {
    justify-content: center;
    display: flex;
    margin-top: calc(-1 * var(--bs-gutter-y));
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
  }

  .question-container-counter {
    display: flex;

    .question-container-counter-current {
      font-weight: bold;
    }

    .question-container-counter-total {
      opacity: 0.5;
    }
  }

  .x-mark-icon {
    cursor: pointer;
    float: right;
    margin-top: 60px;
    width: 20px;
  }

  .question-container-actions {
    align-items: center;
    color: white; /* Set your text color */
    display: flex;
    justify-content: space-around; /* Adjusted to create space between items */
    margin: auto;
    padding: 10px; /* Add padding as needed */
    width: 64%;

    .btn-primary {
      font-size: 14px;
    }

    .icon-logo {
      width: 20px;
      height: 20px;
    }
  }

  .question-container-name {
    .question-container-name-preview.btn-primary {
      display: flex;
      height: 35px;
      background-color: rgb(0 0 0 / 0%);
      border-color: rgb(0 0 0 / 0%);
      opacity: 0.75;
      padding-top: 4px;
      transition: all 0.2s ease-out;

      &:hover {
        height: 35px;
        padding-top: 4px;
        background-color: rgb(255 255 255 / 10%);
      }
    }

    .question-container-name-icon {
      margin: 1px 10px 0 0;
      width: 23px;
    }

    color: white;
    display: flex;
    justify-content: space-between;
    margin-top: 15%;
    margin-bottom: 15%;
  }

  .question-container-input-answer {
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
    resize: none !important;
    outline: none !important;
    margin-top: 25px !important;
    background-color: transparent !important;
    width: 100% !important;
    height: 100% !important;
    font-family: inherit !important;
    font-size: inherit !important;
    color: inherit !important;
    border-radius: 0 !important;
    border-bottom: 0.5px solid rgb(255 255 255 / 60%) !important;

    &::placeholder {
      color: rgb(255 255 255 / 60%) !important;
    }
  }

  .question-container-input-label {
    margin-top: 15px;
    font-size: 22px;
    font-weight: bold;
  }

  .question-container-actions-generate.btn-primary {
    border-color: rgb(255 255 255 / 20%);
    background: #0161ba;
    opacity: 0.9;
    height: 36px;

    &:hover {
      border-radius: 6px;
      border: 1px solid rgb(255 255 255 / 50%);
      background: #006dd2;
      box-shadow: 0 0 32px 0 rgb(255 255 255 / 40%);
    }
  }

  .question-container-actions-logo {
    border-radius: 6px;
    background: #006dd2;
    mix-blend-mode: screen;
    margin-right: 10px;
    width: 20px;
  }

  .question-container-actions-next.btn-primary,
  .question-container-actions-previous.btn-primary {
    background-color: #fff;
    border-color: rgb(255 255 255 / 20%);
    color: black;
    cursor: pointer;
    opacity: 1;
    display: flex;
    width: 120px;
    padding: 8px 12px;
    height: 36px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 6px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    .arrow-left-on-square-icon {
      margin-top: 1px;
    }

    .question-container-actions-button-text {
      margin-right: 5px;
    }

    &:hover,
    &:active {
      background-color: #fff;
      border-color: rgb(255 255 255 / 20%);
      color: black;
      box-shadow:
        0 4px 6px -4px rgb(0 0 0 / 10%),
        0 10px 15px -3px rgb(0 0 0 / 10%);
    }

    &:disabled {
      width: 120px;
      height: 36px;
      background-color: #fff;
      border-color: #fff;
      color: black;
      opacity: 0.8;
      cursor: default;
    }
  }
}

