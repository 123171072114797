.answer-container {
  padding: 20px;

  .answer-container-title {
    margin-top: 30px;
  }
}

.answer-item {
  border-bottom: 1px solid rgb(255 255 255 / 25%);
  margin-top: 20px;

  .answer-item-icon {
    cursor: pointer;
    margin-bottom: 10px;
    width: 20px;
  }

  .answer-item-edit {
    cursor: pointer;
    display: flex;
    margin-top: 10px;
    gap: 6px;
  }

  .answer-item-name {
    font-weight: 600;
    opacity: 0.6;
  }

  .answer-item-problem {
    margin-top: 10px;
  }

  .answer-item-icon-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}
