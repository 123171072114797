.gradient-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, #006dd2 0%, #005cb1 100%);
  z-index: -1;
}

.svg-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./bgCircle.svg');
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: center center;
  z-index: 0;
}

.public-layout {
  position: relative;
  z-index: 1;
}

.logo-icon {
  margin-top: 5px;
  height: 40px;
  width: 40px;
}

.landing-page-title {
  margin-top: 3%;
  display: flex;
  justify-content: center;
}

.landing-page-categories-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.public-layout-logo-suffix {
  font-weight: 600;
}

.public-layout-logo-prefix {
  margin-left: 10px;
  font-weight: 300;
}

.logoutbtn {
  background: none;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  border: none;
  cursor: pointer;
  transition:
    background-color 0.3s,
    color 0.3s;
}

.logoutDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.logoutText {
  justify-content: center;
  align-items: center;
  margin: 3px 6px;
}