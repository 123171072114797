body {
  color: white;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: white;
  text-decoration: none;
}

.public-layout-navigation {
  align-items: center;
  color: white; /* Set your text color */
  display: flex;
  justify-content: space-around; /* Adjusted to create space between items */
  margin: auto;
  padding: 10px; /* Add padding as needed */
  width: 65%;
  margin-top: 32px;

  .public-layout-navigation-link {
    background-color: rgb(0 0 0 / 0%);
    border: none;
    border-radius: 5px;
    padding: 4px 10px;
    transition: all 0.2s ease-out;

    &:hover {
      background-color: rgb(255 255 255 / 10%);
    }
  }
}

/* Style the sub-divs for the logo */
.public-layout-logo {
  display: flex;
  font-size: 24.28px;
}

.public-layout-logo-text {
  display: flex;
  margin-top: 3px;
}

/* Style the individual logo elements */
.public-layout-logo > a {
  margin-right: 10px; /* Adjust spacing between logo elements */
}
