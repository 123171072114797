.slide-over {
  position: fixed;
  top: 0;
  right: -100%;
  background-image: linear-gradient(to bottom, #006DD2 0%, #005CB1 100%);
  transition: right 0.3s ease-in-out;
  z-index: 9999;
  display: flex;
  width: 736px;
  height: 800px;
  padding: 0 48px;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;

  .x-mark-icon {
    cursor: pointer;
    float: right;
    margin-top: 60px;
    width: 20px;
  }
}

.slide-over.open {
  right: 0;
  overflow: auto;
}

.slide-over-content {
  position: absolute;
  top: 0;
  width: 93%;
  background-color: #fff;
  background-image: linear-gradient(to bottom, #006DD2 0%, #005CB1 100%);
  padding: 20px;
  margin-top: -50px;
}
